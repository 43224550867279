<template>
  <section class="einfaches-bild constrain">
    <Image :src="payload.bild" v-view class="animate in-right w-1/2 mx-auto" />
  </section>
</template>
<script>

import Image from '@/components/utils/Image.vue';

export default {
  components: { Image },
  props: { payload: { type: Object, default: Object } },
};
</script>
